import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Events from './pages/Events';
import MonthlyReport from './pages/MonthlyReport';
import Projects from './pages/Projects';
import ViewProjects from './pages/ViewProjects';
import Page404 from './pages/404';


function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path="/events" element={<Events />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/reports" element={<MonthlyReport />} />
          <Route path="/viewprojects/:id" element={<ViewProjects />} />
          <Route path="*" element={<Page404 />} />
          <Route path="/NotFound" element={<Page404 />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter >
  );
}

export default App;
